<template>
  <section class="text-center mx-4 pb-2">
    <h1 class="text-blue fs-1">Getting Involved</h1>
    <div class="row m-auto justify-content-center">
      <div class="col-md col-lg-5">
        <div>
          <img src="@/assets/images/get-involved.jpg" class="img-fluid" alt="Get Involved">
          <p class="fst-italic">Photo by Genevieve Fridley</p>
        </div>
      </div>
      <div class="col-md col-lg-5 d-flex flex-column justify-content-center">
        <p>In past years, <span class="at-rise">At Rise Productions</span> has put
          on a full musical in the spring and a cabaret of original work in the summer. 
          We are still a young company and the coming years may look different, so make
          sure you keep an eye out for any news about auditions or shows. Please email us at 
          <a href="mailto:cnyatrise@gmail.com">cnyatrise@gmail.com</a> if you wish to be 
          added to our mailing list.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>